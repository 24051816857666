.cardform{
	background: #efefef;
	width: auto;
}

.btnPay{
	background: gray;
	color: white;
}

.cardform input{
	background-color: white;
	border: 1px solid #ccc;
}