.totalAlign {
    text-align: right;
    margin-right: 10px;
}

.btnElement {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    border: 2px solid;
}
