.App {
  text-align: center;
  font-family: "Roboto",-apple-system,BlinkMacSystemFont,"Segoe UI","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}



.textField{
  height: 32px;
    width: 200px;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;
  }
    .textField:hover {
      cursor: pointer;
    }
  
    .containerMain{
      display: grid;
      grid-template-columns: 200px 200px 200px;
    }
    .btnBuscar{
      height: 34px;
      width: 32px;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
  
    .barraBusqueda{
  float: right;
   padding: 20px;
   color: red
    }

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
h3.tramos {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 2.5rem;
  letter-spacing: 0.25rem;
  text-shadow: 1px 4px 3px #61616142;
  text-align: left;
  font-weight: 600;
}
/*.block-title p {
  margin: 0;
  text-transform: none;
  font-size: 2.5rem;
  font-weight: 600;
  font-family: 'Satisfy';
  letter-spacing: .2em;
  line-height: 1em;
}*/
footer p {
         font-size: 14px;
         font-family: 'Barlow Condensed';
       }
.site-footer {
  border-top: 1px solid #eceeef;
  padding-top: 30px;
  padding-bottom: 90px;
  position: relative;
}
.App-link {
  color: #61dafb;
}
.imgs_exp{
  width: 275;
  height: 150;
}
.spinner{
  width: 100px !important;
  height: 100px !important;
  border: 10px solid currentColor;
  border-right-color: transparent;

}
.destinations-three__single p{font-size:15px;color:wheat;line-height: 21px;max-width: 80%;}
.destinations-three__content h3{text-shadow: 0 0 10px black;}
.destinations-three__hover-content h3 a{text-shadow: 0 0 10px black;max-width: 80%;}
.destinations-three__hover-content h3 a:hover, .destinations-three__content h3 a:hover {
  color: #ebf5ff;
  max-width: 80%;
}
.destinations-three__single {
  min-height: 300px;
  background-size: cover;
  background-position: center;
  box-shadow: 0px 3px 10px 0px #0d1a3596;
}

.destinations-three__single_custom {
  min-height: 100px;
  min-width: 150px;
  background-size: cover;
  background-position: center;
  box-shadow: 0px 3px 10px 0px #0d1a3596;
  padding-bottom: 15px;
}

.destinations-three__hover-content{text-align: left;}
.site-footer__social {
  position: absolute;
  right: 0;
  font-family: "Barlow Condensed", sans-serif;
}
.site-footer__social a {
  margin-top: -5px;
  background-color: #fff;
  color: #9ca3a9;
  padding: 5px 20px;
  font-size: 16px;
  border-radius: 5px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
svg#icon-home {
    fill: #8c208d;
}
li.breadcrumb-current.test-current-breadcrumb {
    color: #525cdf;
}

@media only screen and (min-width: 680px){}
.book-embed-footer {
    padding: 20px 40px 25px 28px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    background-color: #FFFFFF;
}
button.ben-item.ben-translate-button.capitalize {
    color: white;
}
button.btn-huge.btn-wide.btn-blue.test-add-to-cart-action-enabled {
    background-color: #525cdf;
}

.imagenes {
  display: grid;
  grid-template-columns: repeat(5, 1fr);

}

.imgs {
  min-height: 100px;
  min-width: 150px;
  padding-bottom: 15px;
}

.label-alert{
    font-size: 14px;
    color: red;
    background: rgb(224,204,204);
    width: fit-content;
    height: fit-content;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 10px;
}
.password__input {
  padding-top: 1rem;
  position: relative;
  border: 2px solid #ccc;
  border-radius: 0.5rem;
  font-size: 1.1rem;
  padding: 0.5rem 1rem;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

 




  .password__button {
    background-color: #ccc;
    border: 0;
    border-radius: 0.5rem;
    cursor: pointer;
    margin-left: 1rem;
    padding: 0.5rem 1rem;



  }

  /* .tr:hover {background-color: yellow;}*/

